import * as yup from 'yup';

import messages from '@giro-auth/constants/messagesSchema.util';

export const formSchema = yup.object({
  password: yup.string().required(messages.REQUIRED),
  confirmPassword: yup
    .string()
    .required(messages.REQUIRED)
    .oneOf([yup.ref('password'), null], messages.PASSWORD_VERIFICATION_EQUALS),
});
