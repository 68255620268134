import React from 'react';
import { Box } from '@mui/material';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight';
import FormChangePassword from '@giro-auth/components/Form/FormChangePassword';

import useThemeHook from '@giro-auth/hooks/useTheme.hook';

const ChangePasswordScreen = () => {
  const { mobile, tablet } = useThemeHook();
  return (
    <Box component="section">
      <BackgroundLeftWithRightContent
        title="Cadastre-se agora"
        subtitle="Utilize sua conta para ter todas as facilidades de pagamento da Giro Pagamentos."
        RightContent={<FormChangePassword />}
      />
    </Box>
  );
};

export default ChangePasswordScreen;
