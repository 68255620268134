import React from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import ROUTES from '@giro-auth/constants/routes.util';

import useThemeHook from '@giro-auth/hooks/useTheme.hook';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight/';
import FormLogin from '@giro-auth/components/Form/FormLogin';
import FormWithFooter from '@giro-auth/components/FormWithFooter';
import Modal from '@giro-auth/components/Modal/ModalDefault';
import ForgetPasswordComponent from '@giro-auth/components/ForgetPassword/';

import { propsTypes } from './Login.type';

const LoginScreen: React.FC<propsTypes> = ({ isOpenModal }) => {
  const { mobile, tablet, desktop } = useThemeHook();

  const history = useHistory();

  return (
    <Box component="section">
      {tablet && (
        <BackgroundLeftWithRightContent
          title="Faça login ou cadastre-se"
          subtitle="Utilize sua conta para ter todas as facilidades de pagamento da Giro Pagamentos"
          RightContent={<FormLogin />}
          socialMediaFooter={true}
        />
      )}
      {mobile && <FormWithFooter content={<FormLogin />} socialMediaFooter />}
      {mobile && (
        <Modal isOpen={isOpenModal} content={<ForgetPasswordComponent />} />
      )}
    </Box>
  );
};

export default LoginScreen;
