import reducer from './changePassword.reducer';
import saga from './changePassword.saga';
import * as action from './changePassword.action';
import * as selector from './changePassword.selector';
import * as constant from './changePassword.constant';
// import subscriber from './createAccount.subscriber';
import initialState from './changePassword.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
