import reducer from './confirmEmail.reducer';
import saga from './confirmEmail.saga';
import * as action from './confirmEmail.action';
import * as selector from './confirmEmail.selector';
import * as constant from './confirmEmail.constant';
// import subscriber from './createAccount.subscriber';
import initialState from './confirmEmail.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
