import modalForgetPassword from '../modalForgetPassword';

let nextState = {};
let prevState = {};

const changeName = (dispatch) => {
  console.log('prevState', prevState);
  console.log('nextState', nextState);
};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  changeName(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
