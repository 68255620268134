import * as yup from 'yup';

import messages from '@giro-auth/constants/messagesSchema.util';

export const formSchema = yup.object({
  name: yup.string().required(messages.REQUIRED),
  email: yup.string().email(messages.EMAIL).required(messages.REQUIRED),
  password: yup
    .string()
    .required(messages.REQUIRED)
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      messages.PASSWORD_SPECS
    ),
  confirm_password: yup
    .string()
    .required(messages.REQUIRED)
    .oneOf([yup.ref('password'), null], messages.PASSWORD_VERIFICATION_EQUALS),
});
