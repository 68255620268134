import flow from 'lodash/flow';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';

import useQuery from '@giro-auth/hooks/useQuery.hook';

import ROUTES from '@giro-auth/constants/routes.util';

import changePassword from '@giro-auth/store/changePassword';

import SnackbarComponent from '@giro-auth/components/Snackbar/Snackbar.component';

import { formSchema } from './FormChangePassword.schema';

const FormChangePasswordComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const selectorRedux = {
    error: useSelector(changePassword.selector.selectError),
    loading: useSelector(changePassword.selector.selectLoading),
  };

  const dispatchRedux = {
    FETCH_START: flow(changePassword.action.fetchStart, dispatch),
    RESET_ERROR: flow(changePassword.action.resetError, dispatch),
    RESET_STATE: flow(changePassword.action.resetState, dispatch),
  };

  const handleCreate = (values) => {
    const token = query.get('token');

    dispatchRedux.FETCH_START({
      ...values,
      token,
    });
  };

  useEffect(() => {
    return () => {
      dispatchRedux.RESET_STATE();
    };
  }, []);

  useEffect(() => {
    const [hasError] = selectorRedux.error;

    if (hasError === false) {
      history.push(`${ROUTES.LOGIN}?change=true`);
    }
  }, [selectorRedux.error]);

  return (
    <Fragment>
      <Formik
        initialValues={formSchema.cast(undefined)}
        onSubmit={handleCreate}
        validationSchema={formSchema}
        validateOnMount
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          submitForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              component="div"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: { xs: '100%', md: '100%', lg: '750px' } }}
            >
              <Field
                id="password"
                name="password"
                type="password"
                component={TextField}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label="Senha"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                component={TextField}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                label="Confirmar senha"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Button
                variant="contained"
                color="success"
                size="large"
                css={{ marginTop: 24, color: 'white' }}
                disabled={Object.keys(errors).length > 0}
                onClick={submitForm}
                loading={selectorRedux.loading}
              >
                Trocar senha
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <SnackbarComponent
        dispatchRedux={dispatchRedux}
        selectorRedux={selectorRedux}
      />
    </Fragment>
  );
};

export default FormChangePasswordComponent;
