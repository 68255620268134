import React, { Fragment, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ROUTES from '@giro-auth/constants/routes.util';

import useThemeHook from '@giro-auth/hooks/useTheme.hook';

import forgotPassword from '@giro-auth/store/forgotPassword';

import SnackbarComponent from '@giro-auth/components/Snackbar';

import { formSchema } from './FormForgetPassword.schema';

const FormForgetPasswordComponent = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const dispatchRedux = {
    FETCH_START: flow(forgotPassword.action.fetchStart, dispatch),
    RESET_ERROR: flow(forgotPassword.action.resetError, dispatch),
    RESET_STATE: flow(forgotPassword.action.resetState, dispatch),
  };

  const selectorRedux = {
    error: useSelector(forgotPassword.selector.selectError),
    loading: useSelector(forgotPassword.selector.selectLoading),
  };

  const { mobile } = useThemeHook();

  useEffect(() => {
    return () => {
      dispatchRedux.RESET_STATE();
    };
  }, []);

  useEffect(() => {
    const [hasError] = selectorRedux.error;

    if (hasError === false && !mobile) {
      history.push(ROUTES.SENDED_EMAIL);
    }
  }, [selectorRedux.error]);

  const handleFogetPassword = async (values) => {
    dispatchRedux.FETCH_START(values);
  };

  const initialValues = React.useMemo(() => {
    const obj = { email: '' };
    return obj;
  }, []);

  return (
    <Fragment>
      <Box position="absolute" top={24} left={24}>
        <IconButton onClick={() => history.push(ROUTES.LOGIN)}>
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        onSubmit={handleFogetPassword}
        validationSchema={formSchema}
        validateOnMount
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <Box
            component="div"
            sx={{ marginBottom: { xs: '48px', sm: '48px', md: '48px', lg: 0 } }}
          >
            <Form onSubmit={handleSubmit}>
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                flexDirection="column"
                width={{ xs: '100%', md: '100%', lg: '750px' }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                  mt={2}
                  mb={6}
                >
                  <Typography variant="h3" sx={{ br: { display: 'none' } }}>
                    Não se preocupe, digite o email <br /> cadastrado e nós
                    enviaremos um link
                    <br /> para poder trocar sua senha.
                  </Typography>
                </Box>
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  component={TextField}
                  css={{ width: '100%', marginBottom: 24 }}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  loading={selectorRedux.loading}
                  disabled={Object.keys(errors).length > 0}
                  sx={{
                    marginTop: { xs: 0, sm: 0, md: 0, lg: 6 },
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>

      <SnackbarComponent
        dispatchRedux={dispatchRedux}
        selectorRedux={selectorRedux}
      />
    </Fragment>
  );
};

export default FormForgetPasswordComponent;
