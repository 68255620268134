import React from 'react';

import { Box } from '@mui/material';

import sucess_icon from '@giro-auth/assets/creatAccountSucess/sucess_icon.png';

const CreateAccountSucessIconComponent = () => {
  return <Box component="img" src={sucess_icon} />;
};

export default CreateAccountSucessIconComponent;
