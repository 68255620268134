import { takeLatest, select } from 'redux-saga/effects';

import { selectSended } from './sendedEmail.selector';

import { ACTION_TYPES } from './sendedEmail.constant';

function* handleHelloWorld() {
  const data = yield select(selectSended);

  yield console.log(data);
}

function* watch() {
  yield takeLatest(ACTION_TYPES.SENDED_EMAIL, handleHelloWorld);
}

export default {
  watch,
};
