import React from 'react';
import { Box } from '@mui/material';
import { propsTypes } from '../button.type';

import google_icon from '@giro-auth/assets/global/google_icon.png';

import ButtonDefault from '../ButtonDefault';

const ButtonGoogleComponent: React.FC<propsTypes> = ({ children }) => {
  const redirectToGoogle = `${process.env.REACT_APP_USERS_MS}/api/sso/google`;

  return (
    <ButtonDefault
      variant="outlined"
      onClick={() => (window.location.href = redirectToGoogle)}
    >
      <Box component="img" src={google_icon} mr={2} />
      {children}
    </ButtonDefault>
  );
};

export default ButtonGoogleComponent;
