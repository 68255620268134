import { combineReducers } from 'redux';

import auth from '@giro/shared-store/auth';

import createAccount from './createAccount';
import forgotPassword from './forgotPassword';
import modalForgetPassword from './modalForgetPassword';
import sendedEmail from './sendedEmail';
import login from './login';
import confirmEmail from './confirmEmail';
import changePassword from './changePassword';

const appReducer = combineReducers({
  [modalForgetPassword.name]: modalForgetPassword.reducer,
  [changePassword.name]: changePassword.reducer,
  [confirmEmail.name]: confirmEmail.reducer,
  [login.name]: login.reducer,
  [sendedEmail.name]: sendedEmail.reducer,
  [forgotPassword.name]: forgotPassword.reducer,
  [createAccount.name]: createAccount.reducer,
  [auth.name]: auth.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
