import React from 'react';

import { Box } from '@mui/material';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight';
import SendedIconWithTitleComponent from '@giro-auth/components/SendedIconWithTitleAndButton/';

const SendedEmailScreen = () => {
  return (
    <Box component="section">
      <BackgroundLeftWithRightContent
        title="Solicitar troca de senha"
        subtitle="Informe seu e-mail para solicitar a troca de senha."
        RightContent={
          <SendedIconWithTitleComponent title="Enviamos um email com um link para redefinir a sua senha! Verifique a sua caixa de entrada ou lixo eletrônico." />
        }
      />
    </Box>
  );
};

export default SendedEmailScreen;
