import flow from 'lodash/flow';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-mui';
import NumberFormat from 'react-number-format';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ROUTES from '@giro-auth/constants/routes.util';

import auth from '@giro/shared-store/auth';

import createAccount from '@giro-auth/store/createAccount';

import SnackbarComponent from '@giro-auth/components/Snackbar/Snackbar.component';

import { formSchema } from './FormCreateAccount.schema';

const FormCreateAccountComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectorRedux = {
    error: useSelector(createAccount.selector.selectError),
    loading: useSelector(createAccount.selector.selectLoading),
    data: useSelector(createAccount.selector.selectData),
  };

  const dispatchRedux = {
    FETCH_START: flow(createAccount.action.fetchStart, dispatch),
    RESET_ERROR: flow(createAccount.action.resetError, dispatch),
    RESET_STATE: flow(createAccount.action.resetState, dispatch),
  };

  const dispatchAuthRedux = {
    setToken: flow(auth.action.setToken, dispatch),
  };

  const handleCreate = async (values) => {
    const newValues = { ...values };

    delete newValues.confirm_password;

    dispatchRedux.FETCH_START(newValues);

    return true;
  };

  useEffect(() => {
    return () => {
      dispatchRedux.RESET_STATE();
    };
  }, []);

  useEffect(() => {
    const [hasError] = selectorRedux.error;

    if (hasError === false) {
      dispatchAuthRedux.setToken(selectorRedux.data.token);
    }
  }, [selectorRedux.error, selectorRedux.data]);

  return (
    <Fragment>
      <Box position="absolute" top={24} left={24}>
        <IconButton onClick={() => history.push(ROUTES.LOGIN)}>
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={formSchema.cast(undefined)}
        onSubmit={handleCreate}
        validationSchema={formSchema}
        validateOnMount
      >
        {({ handleSubmit, errors, submitForm }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              component="div"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: { xs: '100%', md: '100%', lg: '750px' } }}
            >
              <Field
                name="name"
                type="text"
                component={TextField}
                label="Nome"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Field
                name="email"
                type="email"
                component={TextField}
                label="Email"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Field
                name="password"
                type="password"
                component={TextField}
                label="Senha"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Field
                name="confirm_password"
                type="password"
                component={TextField}
                label="Confirmar Senha"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                css={{ marginTop: 24, color: 'white' }}
                disabled={Object.keys(errors).length > 0}
                onClick={submitForm}
                loading={selectorRedux.loading}
              >
                Continuar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <SnackbarComponent
        dispatchRedux={dispatchRedux}
        selectorRedux={selectorRedux}
      />
    </Fragment>
  );
};

export default FormCreateAccountComponent;
