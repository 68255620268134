import React from 'react';

import { Box } from '@mui/material';
import useThemeHook from '@giro-auth/hooks/useTheme.hook';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight';
import CreatAcccountIconTitleAndDescription from '@giro-auth/components/CreateAccountIconTitleAndDescription';
import FormWithFooter from '@giro-auth/components/FormWithFooter';

const CreateAccountSucessScreen = () => {
  const { mobile, tablet, desktop } = useThemeHook();
  return (
    <Box component="section">
      {tablet && (
        <BackgroundLeftWithRightContent
          title="Cadastre-se agora"
          subtitle="Utilize sua conta para ter todas as facilidades de pagamento da Giro Pagamentos."
          RightContent={
            <CreatAcccountIconTitleAndDescription
              title="Conta Criada com Sucesso"
              subtitle="Egestas id ut posuere in egestas pulvinar. Leo, quam sed luctus senectus."
            />
          }
        />
      )}
      {mobile && (
        <FormWithFooter
          content={
            <CreatAcccountIconTitleAndDescription
              title="Conta Criada com Sucesso"
              subtitle="Egestas id ut posuere in egestas pulvinar. Leo, quam sed luctus senectus."
            />
          }
        />
      )}
    </Box>
  );
};

export default CreateAccountSucessScreen;
