import { ACTION_TYPES } from './forgotPassword.constant';

export const fetchStart = (payload) => ({
  type: ACTION_TYPES.FETCH.START,
  payload,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetError = () => ({
  type: ACTION_TYPES.RESET.ERROR,
});

export const error = () => ({
  type: ACTION_TYPES.ERROR,
});
