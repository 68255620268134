import { takeLatest, call, put } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';

import postApiAuthsService from '@giro/shared-services/users/postApiAuths.service';

import login from '.';

function* handleFetchStart({ payload }: any) {
  const [success, body] = yield call(postApiAuthsService, payload);

  if (!success) {
    return yield put(login.action.fetchError(body));
  }

  const { token } = body;

  yield put(auth.action.setToken(token));

  return yield put(login.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(login.constant.ACTION_TYPES.FETCH.START, handleFetchStart);
}

export default {
  watch,
};
