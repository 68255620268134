import React from 'react';

import { Box } from '@mui/material';

import email_sucess from '@giro-auth/assets/sendedEmail/email_sucess.png';

const SendedMessageIconComponent = () => {
  return <Box component="img" src={email_sucess} />;
};

export default SendedMessageIconComponent;
