import React from 'react';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight';
import FormForgetPassword from '@giro-auth/components/Form/FormForgetPassword';

const ForgetPasswordScreen = () => {
  return (
    <BackgroundLeftWithRightContent
      title="Solicitar troca de senha"
      subtitle="Informe seu e-mail para solicitar a troca de senha."
      RightContent={<FormForgetPassword />}
    />
  );
};

export default ForgetPasswordScreen;
