import { takeLatest, call, put } from 'redux-saga/effects';

import postApiAccountsConfirmemailService from '@giro/shared-services/users/postApiAccountsConfirmemail.service';

import confirmEmail from '.';

function* handleFetchStart({ payload }: any) {
  const [success, body] = yield call(
    postApiAccountsConfirmemailService,
    payload
  );

  if (!success) {
    return yield put(confirmEmail.action.fetchError(body));
  }

  return yield put(confirmEmail.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(
    confirmEmail.constant.ACTION_TYPES.FETCH.START,
    handleFetchStart
  );
}

export default {
  watch,
};
