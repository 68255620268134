export const NAME = 'modalForgetPassword';

const CONTEXT = 'MODAL_FORGET_PASSWORD';

const MODAL_FORGET_PASSWORD_OPEN = `${CONTEXT}::OPEN`;
const MODAL_FORGET_PASSWORD_CLOSE = `${CONTEXT}::CLOSE`;

export const ACTION_TYPES = {
  MODAL_FORGET_PASSWORD_OPEN,
  MODAL_FORGET_PASSWORD_CLOSE,
};
