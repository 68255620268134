import React from 'react';

import { propsTypes } from '@giro-auth/components/Button/button.type';

import Button from '@giro-auth/components/Button/ButtonDefault';

const SendedMessageButtonCloseComponent: React.FC<propsTypes> = ({
  children,
  ...props
}) => {
  return <Button {...props}>{children}</Button>;
};

export default SendedMessageButtonCloseComponent;
