import reducer from './forgotPassword.reducer';
import saga from './forgotPassword.saga';
import * as action from './forgotPassword.action';
import * as selector from './forgotPassword.selector';
import * as constant from './forgotPassword.constant';
// import subscriber from './forgotPassword.subscriber';
import initialState from './forgotPassword.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
