import React from 'react';
import { Box } from '@mui/material';

import BackgroundLeftWithRightContent from '@giro-auth/components/BackgroundLeftWIthFormRight';
import FormCreateAccount from '@giro-auth/components/Form/FormCreateAccount';
import FormWithFooterComponent from '@giro-auth/components/FormWithFooter/';

import useThemeHook from '@giro-auth/hooks/useTheme.hook';

const CreateAccountScreen = () => {
  const { mobile, tablet } = useThemeHook();
  return (
    <Box component="section">
      {tablet && (
        <BackgroundLeftWithRightContent
          title="Cadastre-se agora"
          subtitle="Utilize sua conta para ter todas as facilidades de pagamento da Giro Pagamentos."
          RightContent={<FormCreateAccount />}
          socialMediaFooter={true}
        />
      )}

      {mobile && (
        <FormWithFooterComponent
          content={<FormCreateAccount />}
          socialMediaFooter
        />
      )}
    </Box>
  );
};

export default CreateAccountScreen;
