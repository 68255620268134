import { all } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';

import createAccount from './createAccount';
import login from './login';
import confirmEmail from './confirmEmail';
import forgotPassword from './forgotPassword';
import changePassword from './changePassword';

function* rootSaga() {
  yield all([
    auth.saga.watch(),
    changePassword.saga.watch(),
    forgotPassword.saga.watch(),
    confirmEmail.saga.watch(),
    login.saga.watch(),
    createAccount.saga.watch(),
  ]);
}

export default rootSaga;
