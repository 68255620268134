import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  password: string;
  confirmPassword: string;
  token?: string;
};

export default async function postApiAccountsChangepasswordService(
  body: BodyType
) {
  const newBody = { ...body };

  const { token } = newBody;

  delete newBody.token;

  return fetch('accounts/change-password', {
    method: 'POST',
    ms: 'USERS',
    body: JSON.stringify(newBody),
    headers: {
      authorization: token,
    },
  });
}
