interface IRoutes {
  LOGIN: string;
  FORGET_PASSWORD: string;
  CREATE_ACCOUNT: string;
  CREATE_ACCOUNT_SUCESS: string;
  SENDED_EMAIL: string;
  CONFIRM_EMAIL: string;
  CHANGE_PASSWORD: string;
  AUTH: string;
}

const NAMESPACE = '/';

const ROUTES: IRoutes = {
  LOGIN: `${NAMESPACE}login`,
  FORGET_PASSWORD: `${NAMESPACE}recuperar-senha`,
  CREATE_ACCOUNT: `${NAMESPACE}create-account`,
  CREATE_ACCOUNT_SUCESS: `${NAMESPACE}create-account-sucess`,
  SENDED_EMAIL: `${NAMESPACE}sended-email`,
  CONFIRM_EMAIL: `${NAMESPACE}confirm-email`,
  CHANGE_PASSWORD: `${NAMESPACE}forget-password`,
  AUTH: `${NAMESPACE}auth`,
};

export default ROUTES;
