import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  email: string;
};

export default async function postApiAccountsForgotpasswordService(
  body: BodyType
) {
  return fetch('accounts/forgot-password', {
    method: 'POST',
    ms: 'USERS',
    body: JSON.stringify(body),
  });
}
