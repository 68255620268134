import { takeLatest, call, put } from 'redux-saga/effects';

import postApiAccountsService from '@giro/shared-services/users/postApiAccounts.service';

import { ACTION_TYPES } from './createAccount.constant';
import createAccount from '.';

function* handleFetchStart({ payload }: any) {
  const payloadToSend = {
    ...payload,
    password_confirmation: payload.password,
    accept: true,
  };

  delete payloadToSend.celphone;

  const [success, body] = yield call(postApiAccountsService, payloadToSend);

  if (!success) {
    return yield put(createAccount.action.fetchError(body));
  }

  return yield put(createAccount.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.FETCH.START, handleFetchStart);
}

export default {
  watch,
};
