import React from 'react';

import { Typography } from '@mui/material';

import { propsTypes } from './CreateAccountSucessTitle.type';

const CreateAccountSucessTitleComponent: React.FC<propsTypes> = ({
  title,
  ...props
}) => {
  return (
    <Typography {...props} variant="h3">
      {title}
    </Typography>
  );
};

export default CreateAccountSucessTitleComponent;
