import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { propsTypes } from './BackgroundImageWithTitle.type';

import BackgroundImage from '@giro-auth/components/BackgroundImage';

const BackgroundImageWithTitleAndDescriptionComponent: React.FC<propsTypes> = ({
  title,
  subtitle,
  variant,
}) => {
  return (
    <BackgroundImage
      variant={variant}
      css={{ display: 'flex', alignItems: 'center' }}
    >
      <Container>
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="60vh"
        >
          <Box component="div" maxWidth={500} width="100%">
            <Typography
              variant="h2"
              fontSize="35px"
              sx={{ color: 'white', fontWeight: 'bold' }}
            >
              {title}
            </Typography>
          </Box>
          <Box component="div" maxWidth={500} width="100%">
            <Typography
              variant="body1"
              sx={{ color: 'white', fontWeight: 'light' }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Container>
    </BackgroundImage>
  );
};

export default BackgroundImageWithTitleAndDescriptionComponent;
