import flow from 'lodash/flow';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import useQuery from '@giro-auth/hooks/useQuery.hook';

import confirmEmail from '@giro-auth/store/confirmEmail';

import ROUTES from '@giro-auth/constants/routes.util';

const ConfirmEmailScreen = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    FETCH_START: flow(confirmEmail.action.fetchStart, dispatch),
    RESET_ERROR: flow(confirmEmail.action.resetError, dispatch),
  };

  const selectorRedux = {
    error: useSelector(confirmEmail.selector.selectError),
  };

  useEffect(() => {
    const token = query.get('token');
    const email = query.get('email');

    dispatchRedux.FETCH_START({ token, email });
  }, []);

  useEffect(() => {
    const [hasError] = selectorRedux.error;

    if (hasError === false) {
      history.push(`${ROUTES.LOGIN}?confirm=true`);
    }
  }, [selectorRedux.error]);

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={2}
    >
      <CircularProgress />
    </Box>
  );
};

export default ConfirmEmailScreen;
