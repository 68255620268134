import { flow } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';

import auth from '@giro/shared-store/auth';

import useQuery from '@giro/shared-hooks/useQuery.hook';

const AuthScreen = () => {
  const query = useQuery();

  const dispatch = useDispatch();

  const dispatchAuthRedux = {
    SET_TOKEN: flow(auth.action.setToken, dispatch),
  };

  useEffect(() => {
    const token = query.get('token');

    dispatchAuthRedux.SET_TOKEN(token);
  }, []);

  return (
    <Box
      display="flex"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default AuthScreen;
