import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import ROUTES from '@giro-auth/constants/routes.util';

import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import modalForgetPassword from '@giro-auth/store/modalForgetPassword/';
import useThemeHook from '@giro-auth/hooks/useTheme.hook';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '@giro/shared-components/Logo.component';
import Button from '@giro-auth/components/Button/ButtonDefault';

const CloseButtonWithLogoComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const { mobile } = useThemeHook();

  const dispatchRedux = {
    modalClose: flow(modalForgetPassword.action.modalClose, dispatch),
  };

  const handleClose = () => {
    if (!mobile) {
      history.goBack();
    }

    dispatchRedux.modalClose();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ height: { xs: '0%', md: '0%', lg: '60vh' } }}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="flex-end"
          mt={{ xs: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
        >
          <Button variant="text" onClick={handleClose}>
            <CloseIcon width="50px" height="50px" sx={{ color: '#CCCCCC' }} />
          </Button>
        </Box>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {location.pathname !== ROUTES.CREATE_ACCOUNT_SUCESS && (
            <Box component="div" mb={5}>
              <Logo variant="new" width="200" />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CloseButtonWithLogoComponent;
