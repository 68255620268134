import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type Props = {
  dispatchRedux: {
    RESET_ERROR: any;
  };
  selectorRedux: {
    error: any;
  };
};

const SnackbarComponent = ({ dispatchRedux, selectorRedux }: Props) => {
  const [hasError, payloadError = {}] = selectorRedux.error;

  return (
    <Snackbar
      open={hasError === true}
      autoHideDuration={5000}
      onClose={dispatchRedux.RESET_ERROR}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        onClose={dispatchRedux.RESET_ERROR}
        severity="error"
        elevation={6}
        variant="filled"
      >
        {payloadError?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
