import reducer from './sendedEmail.reducer';
import saga from './sendedEmail.saga';
import * as action from './sendedEmail.action';
import * as selector from './sendedEmail.selector';
import * as constant from './sendedEmail.constant';
import subscriber from './sendedEmail.subscriber';
import initialState from './sendedEmail.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  name: constant.NAME,
};
