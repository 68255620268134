import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  name: string;
  email: string;
  phone_number: string;
  password: string;
  password_confirmation: string;
  accept: boolean;
};

export default async function postApiAccountsService(body: BodyType) {
  return fetch('accounts', {
    method: 'POST',
    ms: 'USERS',
    body: JSON.stringify(body),
  });
}
