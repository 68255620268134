import { takeLatest, select } from 'redux-saga/effects';

import { selectIsOpen } from './modalForgetPassword.selector';

import { ACTION_TYPES } from './modalForgetPassword.constant';

function* handleHelloWorld() {
  const data = yield select(selectIsOpen);

  yield console.log(data);
}

function* watch() {
  yield takeLatest(ACTION_TYPES.MODAL_FORGET_PASSWORD_OPEN, handleHelloWorld);
}

export default {
  watch,
};
