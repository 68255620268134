import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import ROUTES from '@giro-auth/constants/routes.util';

import { Box, Typography, Container } from '@mui/material';
import { propsTypes } from './BackgroundLeftWithRightContent.type';

import Logo from '@giro/shared-components/Logo.component';
import CloseIcon from '@mui/icons-material/Close';

import BackgroundImageWithTitleAndDescriptionComponent from '@giro-auth/components/BackgroundImageWithTitleAndDescription';
import Button from '@giro-auth/components/Button/ButtonDefault';
import SocialMediaAuthButtonsComponent from '@giro-auth/components/SocialMediaAuthButtons';

const BackgroundLeftWithRightContent: React.FC<propsTypes> = ({
  title,
  RightContent,
  subtitle,
  socialMediaFooter,
}) => {
  const location = useLocation();
  const history = useHistory();

  const isLoginPage = location.pathname === ROUTES.LOGIN;

  return (
    <Box component="div" gridTemplateColumns="4fr 4.8fr" display="grid">
      <BackgroundImageWithTitleAndDescriptionComponent
        variant="background 01"
        title={title}
        subtitle={subtitle}
      />
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        <Container>
          <Box display="flex" flexDirection="column" width="100%">
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {location.pathname !== ROUTES.CREATE_ACCOUNT_SUCESS && (
                <Box
                  component="div"
                  mb={5}
                  css={{ cursor: 'pointer' }}
                  onClick={() => {
                    (window as any).location.href =
                      process.env.REACT_APP_APP_URL;
                  }}
                >
                  <Logo variant="new" width={'300'} />
                </Box>
              )}
              <Box>
                {RightContent}
                {socialMediaFooter && <SocialMediaAuthButtonsComponent />}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default BackgroundLeftWithRightContent;
