import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import auth from '@giro/shared-store/auth';

import useQuery from '@giro-auth/hooks/useQuery.hook';

import ROUTES from '@giro-auth/constants/routes.util';

import LoginScreen from './screens/Login';
import ForgetPasswordScreen from './screens/ForgetPassword';
import CreateAccount from './screens/CreateAccount';
import CreateAccountSucess from './screens/CreateAccountSucess';
import SendedEmailScreen from './screens/SendedEmail';
import ConfirmEmailScreen from './screens/ConfirmEmail';
import ChangePasswordScreen from './screens/ChangePassword';
import AuthScreen from './screens/Auth.screen';

export default function Routes() {
  const query = useQuery();

  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
  };

  useEffect(() => {
    const redirectUrl = query.get('redirectUrl');
    const shouldHaveRedirectUrl = !!redirectUrl;

    if (shouldHaveRedirectUrl) {
      sessionStorage.setItem('redirectUrl', redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (selectorRedux.signedIn) {
      const redirectUrlLocal = sessionStorage.getItem('redirectUrl');

      const shouldHaveRedirectUrlLocal = !!redirectUrlLocal;

      if (shouldHaveRedirectUrlLocal) {
        sessionStorage.removeItem('redirectUrl');

        (window as any).location.href = redirectUrlLocal;
      }

      if (!shouldHaveRedirectUrlLocal) {
        (window as any).location.href = process.env.REACT_APP_APP_URL;
      }
    }
  }, [selectorRedux.signedIn]);

  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={LoginScreen} />

      <Route exact path={ROUTES.AUTH} component={AuthScreen} />

      <Route
        exact
        path={ROUTES.CHANGE_PASSWORD}
        component={ChangePasswordScreen}
      />

      <Route
        exact
        path={ROUTES.FORGET_PASSWORD}
        component={ForgetPasswordScreen}
      />

      <Route exact path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmailScreen} />

      <Route exact path={ROUTES.CREATE_ACCOUNT} component={CreateAccount} />

      <Route
        exact
        path={ROUTES.CREATE_ACCOUNT_SUCESS}
        component={CreateAccountSucess}
      />
      <Route exact path={ROUTES.SENDED_EMAIL} component={SendedEmailScreen} />

      <Redirect to={ROUTES.LOGIN} />
    </Switch>
  );
}
