import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { TextField } from 'formik-mui';

import SnackbarComponent from '@giro-auth/components/Snackbar/Snackbar.component';

import ROUTES from '@giro-auth/constants/routes.util';

import useQuery from '@giro-auth/hooks/useQuery.hook';
import useThemeHook from '@giro-auth/hooks/useTheme.hook';

import modalForgetPassword from '@giro-auth/store/modalForgetPassword';
import login from '@giro-auth/store/login';

import { formSchema } from './FormLogin.schema';

const FormLoginComponent = () => {
  const query = useQuery();

  const history = useHistory();

  const dispatch = useDispatch();

  const { mobile } = useThemeHook();

  const dispatchRedux = {
    modalOpen: flow(modalForgetPassword.action.modalOpen, dispatch),
  };

  const dispatchLoginRedux = {
    FETCH_START: flow(login.action.fetchStart, dispatch),
    RESET_ERROR: flow(login.action.resetError, dispatch),
  };

  const selectorLoginRedux = {
    error: useSelector(login.selector.selectError),
    loading: useSelector(login.selector.selectLoading),
  };

  const handleLogin = async (values) => {
    dispatchLoginRedux.FETCH_START(values);
  };

  const handleForgetPassword = () => {
    if (!mobile) {
      return history.push(ROUTES.FORGET_PASSWORD);
    }

    return dispatchRedux.modalOpen();
  };

  const shouldHaveCreateAccount = query.get('create');
  const shouldHaveConfirmEmail = query.get('confirm');
  const shouldHaveChangePassword = query.get('change');

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleLogin}
        validationSchema={formSchema}
        validateOnMount
      >
        {({ handleSubmit, errors, submitForm }) => (
          <Form onSubmit={handleSubmit}>
            {shouldHaveChangePassword && (
              <Alert severity="success" sx={{ marginBottom: 4 }}>
                <b>Senha trocada com sucesso!</b> <br /> Faça login para
                continuar
              </Alert>
            )}

            {shouldHaveConfirmEmail && (
              <Alert severity="success" sx={{ marginBottom: 4 }}>
                <b>Conta confirmada com sucesso!</b> <br /> Faça login para
                continuar
              </Alert>
            )}

            {shouldHaveCreateAccount && (
              <Alert severity="success" sx={{ marginBottom: 4 }}>
                <b>Conta criada com sucesso!</b> <br /> Confira sua caixa de
                entrada para confirmar sua conta. <br />
                Se não encontrar o e-mail verifique em Lixo Eletrônico.
              </Alert>
            )}

            <Box
              component="div"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: { xs: '100%', md: '100%', lg: '750px' } }}
            >
              <Field
                name="email"
                type="email"
                component={TextField}
                label="Email"
                css={{ width: '100%', marginBottom: 24 }}
              />
              <Field
                name="password"
                type="password"
                component={TextField}
                label="Senha"
                css={{ width: '100%', marginBottom: 24 }}
              />

              <Button
                variant="text"
                sx={{
                  color: 'info.main',
                  '&:hover': { backgroundColor: 'transparent' },
                  textTransform: 'lowercase',
                  justifyContent: 'flex-end',
                }}
                onClick={handleForgetPassword}
              >
                Esqueci a senha
              </Button>
              <LoadingButton
                variant="contained"
                size="large"
                css={{ marginTop: 24 }}
                onClick={submitForm}
                loading={selectorLoginRedux.loading}
                disabled={Object.keys(errors).length > 0}
              >
                Entrar
              </LoadingButton>
              <Box
                display="flex"
                justifyContent="center"
                css={{ marginTop: 24 }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size="large"
                  onClick={() => history.push(ROUTES.CREATE_ACCOUNT)}
                >
                  Criar conta
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      <SnackbarComponent
        dispatchRedux={dispatchLoginRedux}
        selectorRedux={selectorLoginRedux}
      />
    </>
  );
};

export default FormLoginComponent;
