import reducer from './modalForgetPassword.reducer';
import saga from './modalForgetPassword.saga';
import * as action from './modalForgetPassword.action';
import * as selector from './modalForgetPassword.selector';
import * as constant from './modalForgetPassword.constant';
import subscriber from './modalForgetPassword.subscriber';
import initialState from './modalForgetPassword.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  name: constant.NAME,
};
