import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import modalForgetPassword from '@giro-auth/store/modalForgetPassword';

import LoginScreen from './Login.screen';

const LoginContainer = () => {
  const dispatch = useDispatch();

  const selectorRedux = {
    isOpen: useSelector(modalForgetPassword.selector.selectIsOpen),
  };

  return <LoginScreen isOpenModal={selectorRedux.isOpen} />;
};

export default LoginContainer;
