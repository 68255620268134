import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import ROUTES from '@giro-auth/constants/routes.util';

import ButtonGoogle from '../Button/ButtonGoogle';
import ButtonFacebook from '../Button/ButtonFacebook';

const SocialMediaAuthButtonsComponent = () => {
  const location = useLocation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box component="div" mt={2} mb={2}>
        <Typography variant="subtitle2">
          {location.pathname === ROUTES.CREATE_ACCOUNT
            ? 'Ou continue usando:'
            : 'ou entre com:'}
        </Typography>
      </Box>
      <Box
        component="div"
        display="grid"
        gridTemplateColumns={{ xs: '1fr', md: '1fr', lg: '1fr 1fr' }}
        gap={2}
        width={{ xs: '100%', md: '100%', lg: '750px' }}
      >
        <ButtonGoogle size="large">Google</ButtonGoogle>
        <ButtonFacebook
          variant="contained"
          size="large"
          sx={{ backgroundColor: 'info.main' }}
        >
          Facebook
        </ButtonFacebook>
      </Box>
    </Box>
  );
};

export default SocialMediaAuthButtonsComponent;
