import React from 'react';

import { useSelector } from 'react-redux';
import sendedEmail from '@giro-auth/store/sendedEmail/';

import { Box } from '@mui/material';

import CloseButtonWithLogo from '@giro-auth/components/CloseButtonWithLogo';
import FormForgetPassword from '@giro-auth/components/Form/FormForgetPassword';
import SendedIconWithTitleComponent from '@giro-auth/components/SendedIconWithTitleAndButton/';

const ForgetPasswordComponent = () => {
  const selectorRedux = {
    sended: useSelector(sendedEmail.selector.selectSended),
  };

  return (
    <Box component="div" sx={{ button: { marginTop: 0 } }}>
      <CloseButtonWithLogo />
      {!selectorRedux.sended && <FormForgetPassword />}
      {selectorRedux.sended && (
        <SendedIconWithTitleComponent title="Enviamos um email com um link para redefinir a sua senha! Verifique a sua caixa de entrada ou lixo eletrônico." />
      )}
    </Box>
  );
};

export default ForgetPasswordComponent;
