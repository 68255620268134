import React from 'react';

import { Box, Container } from '@mui/material/';

import CloseButtonWithLogoComponent from '@giro-auth/components/CloseButtonWithLogo';
import SocialMediaAuthButtons from '@giro-auth/components/SocialMediaAuthButtons';
import Footer from '../Footer/Footer.component';

type propsTypes = {
  content: React.ReactNode;
  socialMediaFooter?: boolean;
};

const FormWithFooterComponent: React.FC<propsTypes> = ({
  content,
  socialMediaFooter,
}) => {
  return (
    <Box component="div">
      <CloseButtonWithLogoComponent />
      <Box component="div" mb={10}>
        <Container>
          {content}
          {socialMediaFooter && <SocialMediaAuthButtons />}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default FormWithFooterComponent;
