import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useThemeHook = () => {
  const {
    breakpoints: { down, up },
  } = useTheme();

  const mobile = useMediaQuery(down('md'));
  const tablet = useMediaQuery(up('md'));
  const desktop = useMediaQuery(up('lg'));
  return { mobile, tablet, desktop };
};

export default useThemeHook;
