import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { propsTypes } from './SendedIconWithTitleAndButton.type';

import ROUTES from '@giro-auth/constants/routes.util';

import SendedMessageIcon from '@giro-auth/components/SendedMessageIcon';
import SendedMessageTitle from '@giro-auth/components/SendedMessageTitle/';
import SendedMessageButton from '@giro-auth/components/Button/SendedMessageButtonClose';

const SendedIconWithTitleComponent: React.FC<propsTypes> = ({ title }) => {
  const history = useHistory();

  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      width={{ xs: '100%', md: '100%', lg: '750px' }}
    >
      <Box component="div" mt={4}>
        <SendedMessageIcon />
      </Box>
      <Box component="div" mt={6}>
        <SendedMessageTitle title={title} variant="h3" />
      </Box>
      <Box component="div" mt={6} width="100%">
        <SendedMessageButton
          variant="contained"
          size="large"
          onClick={() => history.push(ROUTES.LOGIN)}
          sx={{
            width: '100%',
            backgroundColor: 'gray.200',
            color: 'info.dark2',
          }}
        >
          Voltar para o login
        </SendedMessageButton>
      </Box>
    </Box>
  );
};

export default SendedIconWithTitleComponent;
