import React from 'react';

import { Typography } from '@mui/material';

import { propsTypes } from './CreateAccountSubtitle.type';

const CreateAccountSubtitleComponent: React.FC<propsTypes> = ({
  subtitle,
  ...props
}) => {
  return <Typography {...props}>{subtitle}</Typography>;
};

export default CreateAccountSubtitleComponent;
