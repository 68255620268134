import React from 'react';
import Box from '@mui/material/Box';
import { propsTypes } from './BackgroundImage.type';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import background01 from '@giro-auth/assets/global/bg-md.png';
import background02 from '@giro-auth/assets/global/fundo02.png';

const styles = {
  root: css({
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
  backgroundOne: css({
    backgroundImage: `url(${background01})`,
  }),
  backgroundTwo: css({
    backgroundImage: `url(${background02})`,
  }),
};

const BackgroundImageStyled = styled<any>(Box)`
  ${styles.root}
  ${(props) => props.variant === 'background 01' && styles.backgroundOne}
  ${(props) => props.variant === 'background 02' && styles.backgroundTwo}
`;

const BackgroundImageComponent: React.FC<propsTypes> = ({
  children,
  variant,
  ...props
}) => {
  return (
    <BackgroundImageStyled variant={variant} {...props}>
      {children}
    </BackgroundImageStyled>
  );
};

export default BackgroundImageComponent;
