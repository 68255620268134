import React from 'react';

import { Modal, Box, Container } from '@mui/material';

import { propsTypes } from '@giro-auth/components/Modal/Modal.type';

const ModalDefaultComponent: React.FC<propsTypes> = ({ isOpen, content }) => {
  return (
    <Modal
      open={isOpen || false}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        component="div"
        sx={{
          color: 'black',
          width: '90vw',
          padding: '20px 0 ',
          backgroundColor: 'white',
          boxShadow: '0px 24px 48px rgba(0, 33, 82, 0.16)',
          borderRadius: '8px',
          outline: 'none',
        }}
      >
        <Container>{content}</Container>
      </Box>
    </Modal>
  );
};

export default ModalDefaultComponent;
