import React from 'react';

import { Box } from '@mui/material';

import { propsTypes } from './CreateAccounticonTitleAndDescription.type';

import CreateAccountSucessIconComponent from '@giro-auth/components/CreateAccountSucessIcon';
import CreateAccountSucessTitle from '@giro-auth/components/CreateAccountSucessTitle';
import CreateAccountSucessSubtitle from '@giro-auth/components/CreateAccountSubtitle';
import CreateAccountSucessButton from '@giro-auth/components/Button/CreateAccountSucessButton';
const CreateAccountIconTitleAndDescription: React.FC<propsTypes> = ({
  title,
  subtitle,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      width={{ xs: '100%', md: '100%', lg: '750px' }}
    >
      <Box component="div" mt={4}>
        <CreateAccountSucessIconComponent />
      </Box>
      <Box component="div" mt={6}>
        <CreateAccountSucessTitle title={title} variant="h3" />
      </Box>
      <Box component="div" mt={4}>
        <CreateAccountSucessSubtitle
          subtitle={subtitle}
          variant="body2"
          sx={{ color: 'gray.400' }}
        />
      </Box>
      <Box component="div" mt={6} width="100%">
        <CreateAccountSucessButton
          variant="contained"
          color="success"
          size="large"
          sx={{ width: '100%', color: 'white' }}
        >
          Continuar
        </CreateAccountSucessButton>
      </Box>
    </Box>
  );
};

export default CreateAccountIconTitleAndDescription;
