import reducer from './createAccount.reducer';
import saga from './createAccount.saga';
import * as action from './createAccount.action';
import * as selector from './createAccount.selector';
import * as constant from './createAccount.constant';
// import subscriber from './createAccount.subscriber';
import initialState from './createAccount.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
