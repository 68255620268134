import { createReducer } from '@reduxjs/toolkit';

import initialState from './modalForgetPassword.initialState';
import { ACTION_TYPES } from './modalForgetPassword.constant';

const handleOpen = (state, action) => {
  state.isOpen = true;
};

const handleClose = (state, action) => {
  state.isOpen = false;
};

export default createReducer(initialState, {
  [ACTION_TYPES.MODAL_FORGET_PASSWORD_OPEN]: handleOpen,
  [ACTION_TYPES.MODAL_FORGET_PASSWORD_CLOSE]: handleClose,
});
