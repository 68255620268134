import { createReducer } from '@reduxjs/toolkit';

import initialState from './sendedEmail.initialState';
import { ACTION_TYPES } from './sendedEmail.constant';

const handleSended = (state, action) => {
  state.sended = true;
};

export default createReducer(initialState, {
  [ACTION_TYPES.SENDED_EMAIL]: handleSended,
});
